import request from '../utils/request'


//获取会议通知详情
export function getMeetingNoticeDetail(id){

    return request({

        url:`/api/meeting/getMeetingById/${id}`
    })
}