<template>
    <div class="meeting-notice">

      <div class="body">
       
      <div class="title">{{ detail.title }}</div>

      <div class="head">

         <div class="unit">{{ detail.publishingUnit||'' }}</div>
         <div class="time">{{ detail.createTime }}</div>

      </div>

      <div class="content" v-html="detail.content"></div>

      <div class="attachment">

          <div class="item" v-for="(item,index) in attachmentList" :key="index">
           <div class="title">{{index+1}}.{{item.name}}</div>
           <el-button type="text"  @click="handleDownload(item)">下载</el-button>
        </div>

      </div>
    </div>

    </div>
</template>

<script>

  import {getMeetingNoticeDetail} from '@/api/meetingNotice'

   export default{

         

    data(){
        return {
           
            detail:{},
            attachmentList:[]
            

        }

    },
    created(){

    
         let id = this.$route.params.id

         if(id){
          getMeetingNoticeDetail(id).then(res=>{

            document.title = res.data.title
            this.detail=res.data;

            try{

              this.attachmentList = JSON.parse( res.data.urlFileList||'[]')
            }catch{
              this.attachmentList =[]
            }
            
          })
         }
     

    },
    methods:{

        isWeixin(){

         const ua = navigator.userAgent.toLowerCase();
         return ua.indexOf('micromessenger') != -1;
       },

       handleDownload(item){

      
        fetch(item.url).then(response=>response.blob()).then(res=>{

          
          let a = document.createElement('a')

          a.href = this.isWeixin()?item.url:URL.createObjectURL(res)
          a.download=item.name
          a.style.display='none'
          document.body.appendChild(a)
          a.click()
          a.remove()
          URL.revokeObjectURL(res)
        })

       }
       
    }
   }


</script>

<style lang="less">

    .meeting-notice{

   
        width: 100%;
       
        .body{
          margin: 15px;

          .title{

            font-weight: bold;
            
          }

          .head{
            margin-top: 15px;
            display: flex;
            font-size: 14px;
            .unit{
              color:#2440b3;
            }
            .time{
              margin-left: 20px;

            }
          }


        .content{
            margin-top: 15px;
            word-wrap: break-word; /* 使得长单词或URL可以换行 */
            overflow-wrap: break-word; /* 确保跨浏览器兼容性 */

            p {
              line-height: 2;
            }

            /* table 样式 */
table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

strong{
    font-weight: bold;
} 

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
pre code {
  display: block;
}

/* ul ol 样式 */
ul, ol {
  margin: 10px 0 10px 20px;
}
 
        }

        .attachment{

          .item{
          
            display: flex;
            align-items: center;
            .title{
              font-size: 15px;
              margin-right: 15px;
            }
          }
        } 
   

    }
  
  
  }


  @media screen and (min-width:960px){
    
     .meeting-notice{

      width: 1000px;
      margin: auto;


      .title{
        font-size: 22px;
      }

      .head{
       
            font-size: 18px;
      }

     }

  }



</style>